import React from 'react'
import { css } from '@emotion/core'
import { font } from '../constants/styles'

const container = css`
  max-width: 720px;
  margin: 0 auto;
  font-size: ${font.textSize};
  margin-bottom: 200px;
`

const text = css`
  padding-top: 24px;
  font-size: ${font.textSize};
`

export const CaseStudiesText = (props) => {
  const overview = 0
  const openingCash = 1
  const openingCashText = 2
  const point = 3
  const pointText = 4
  return (
    <section css={container}>
      <p>{props.textData[overview]}</p>
      <h3 css={text}>{props.textData[openingCash]}</h3>
      <p css={text}>{props.textData[openingCashText]}</p>
      <h3 css={text}>{props.textData[point]}</h3>
      <p css={text}>{props.textData[pointText]}</p>
    </section>
  )
}
