import React from "react";
import { Layout } from "../../components/Layout";
import { Head } from "../../components/Head";
import { CaseStudiesTitle } from "../../components/CaseStudiesTitle";
import { CaseStudiesText } from "../../components/CaseStudiesText";

export default function CS_Orthopedics() {
  const subTitle = "整形外科";
  const title = "Orthopedics";
  const overview = [
    "高齢者人口の増加に伴い、運動機能のリハビリを必要とする患者様が増加傾向にある診療科目です",
    <br />,
    "接骨院、鍼灸院とも競合することを考慮する必要があります",
  ];
  const openingCash = "おおよその開業資金";
  const openingCashText = [
    "土地、建物　7,000万円～15,000万円",
    <br />,
    "設備　1,900万円〜2,500万円",
    <br />,
    "電子カルテ、レジスター、コピー複合機、診察用ベッド、X線撮影装置",
    <br />,
    "DICOM（ダイコム）画像を見られる高精細モニターとPACS、施術台・リハビリ機器 など",
  ];
  const point = "ポイント";
  const pointText = [
    "・無駄な設備投資は控える（採算がとりにくい設備等があるため）",
    <br />,
    "・より多くの患者様が待てる待合室を作る",
    <br />,
    "・理学療法士に投資する",
    <br />,
    "・駐車場の広さ（駐車場から医院までの距離をできるだけ短く）",
    <br />,
    "・エレベーターの設置",
    <br />,
    <br />,
    "治療効果の高い理学療法士を採用し高い評判を獲得することが重要です",
    <br />,
    "診療報酬単価が比較的低いためより多くの患者様を確保し、足腰の悪い患者様に配慮した設計が必須です",
  ];

  return (
    <Layout>
      <Head props={subTitle} />
      <CaseStudiesTitle title={[title, subTitle]} />
      <CaseStudiesText
        textData={[overview, openingCash, openingCashText, point, pointText]}
      />
    </Layout>
  );
}
