import React from 'react'
import { css } from '@emotion/core'
import { font, color } from '../constants/styles'

const titleArea = css`
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  color: ${color.mainD};
  font-weight: normal;
`

const title = css`
  font-family: ${font.TitleFontFamily};
  font-size: ${font.textSize};
`

const subTitle = css`
  font-size: ${font.NomaltitleSize};
  color: ${color.black};
  font-family: ${font.TitleFontFamily};
  padding-top: 20px;
`

export const CaseStudiesTitle = (props) => {
  const en = 0
  const ja = 1
  return (
    <section css={titleArea}>
      <h2 css={title}>{props.title[en]}</h2>
      <p css={subTitle}>{props.title[ja]}</p>
    </section>
  )
}
